import React from "react";

const About: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col text-white relative">
      {/* Video background */}
      <video
        autoPlay
        muted
        className="absolute top-0 left-0 w-full h-full object-cover"
      >
        <source src={require('../video/my_video2.mp4')} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Gradient overlay */}
      <div
        className="absolute top-0 left-0 w-full h-full bg-black opacity-50"
        style={{ zIndex: 1 }}
      ></div>

      {/* Content */}
      <main className="flex-grow relative z-10">
        {/* About Us Section */}
        <section className="bg-secondary text-white pt-20 mx-5 sm:mx-auto">
          <div className="container mx-auto text-center">
            <h2
              className="text-3xl font-bold mb-8"
              style={{ fontSize: "2.5rem", lineHeight: "1.3" }}
            >
              About Us
            </h2>
            <p
              className="text-lg mb-8"
              style={{
                fontSize: "1.2rem",
                lineHeight: "1.6",
                fontWeight: "400",
              }}
            >
              Welcome to the Future of Gamified Sustainability. At Reker World,
              we're revolutionizing the way we tackle climate change. Our
              groundbreaking platform blends futuristic technology and
              gamification to reward you with CO₂ offset credits for making
              eco-friendly choices in your daily life. Whether it’s choosing
              public transport, reducing carbon footprint, or spending, your
              actions now have a tangible, real-world impact. Ready to play your
              part in the fight against climate change? Join us and let’s gamify
              the future together, one CO₂ offset at a time.
            </p>
          </div>
        </section>

        {/* Vision Section */}
        <section className="py-20 mx-5 sm:mx-auto">
          <div className="container mx-auto text-center">
            <h2
              className="text-3xl font-bold mb-8"
              style={{ fontSize: "2.5rem", lineHeight: "1.3" }}
            >
              Our Vision
            </h2>
            <p
              className="text-lg mb-8"
              style={{
                fontSize: "1.2rem",
                lineHeight: "1.6",
                fontWeight: "400",
              }}
            >
              ​We believe that everyone has a role to play in combating climate
              change. By reimagining how we approach sustainability, we’re
              paving the way for a future where reducing your carbon footprint
              is as engaging and exciting as playing your favorite game. Through
              innovative gamification, we encourage our users to make
              eco-friendly choices in their daily lives, from commuting to
              spending, and reward them with valuable carbon offset credits.
            </p>
          </div>
        </section>
      </main>
    </div>
  );
};

export default About;
