import React, { useState, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../firebaseConfig";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash, FaEnvelope, FaLock } from "react-icons/fa";
import LogoCarousel from "./ExploreCarousel";
import myGif from "../video/gif.gif";

const LoginSignup: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleAuth = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (isLogin) {
        await signInWithEmailAndPassword(auth, email, password);
        toast.success("Login successful");
        navigate("/explore");
      } else {
        await createUserWithEmailAndPassword(auth, email, password);
        toast.success("Account created successfully");
        setIsLogin(true);
      }
    } catch (error: any) {
      toast.error(error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-gray-900 via-black to-gray-900">
      {/* Animated Background */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute w-96 h-96 -top-48 -left-48 bg-yellow-500/30 rounded-full blur-3xl animate-pulse"></div>
        <div className="absolute w-96 h-96 -bottom-48 -right-48 bg-yellow-500/20 rounded-full blur-3xl animate-pulse delay-1000"></div>
      </div>

      {/* Hero Section */}
      <header className="relative pt-32 pb-16 px-6 max-w-7xl mx-auto">
        <div className="flex flex-col lg:flex-row items-center justify-between gap-16">
          <div className="lg:w-1/2 space-y-8">
            <h1 className="text-7xl font-black tracking-tight">
              <span className="inline-block text-transparent bg-clip-text bg-gradient-to-r from-yellow-300 via-yellow-500 to-yellow-600 animate-gradient">
                Engage.
              </span>
              <br />
              <span className="inline-block text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 animate-gradient delay-150">
                Reward.
              </span>
              <br />
              <span className="inline-block text-transparent bg-clip-text bg-gradient-to-r from-yellow-500 via-yellow-600 to-yellow-700 animate-gradient delay-300">
                Retain.
              </span>
            </h1>
            <p className="text-2xl text-gray-300 leading-relaxed">
              Transform engagement through next-gen AR-driven rewards.
            </p>
            <button
              onClick={() => window.scrollTo({ top: window.innerHeight, behavior: 'smooth' })}
              className="group relative px-8 py-4 bg-gradient-to-r from-yellow-400 to-yellow-600 rounded-xl font-semibold text-black overflow-hidden transition-all duration-300 hover:scale-105"
            >
              <span className="relative z-10">Learn More</span>
              <div className="absolute inset-0 bg-gradient-to-r from-yellow-300 to-yellow-500 translate-y-full group-hover:translate-y-0 transition-transform duration-300"></div>
            </button>
          </div>
          <div className="lg:w-1/2 relative">
            <div className="absolute inset-0 bg-gradient-to-r from-yellow-400/20 to-yellow-600/20 blur-3xl rounded-full animate-pulse"></div>
            <img
              src={myGif}
              alt="Interactive AR engagement"
              className="relative rounded-3xl shadow-2xl transform hover:scale-105 transition-transform duration-500 hover:shadow-yellow-500/20"
            />
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="relative px-6 py-24 max-w-7xl mx-auto">
        <div className="grid lg:grid-cols-2 gap-16 items-start">
          {/* Features Section */}
          <div className="space-y-8">
            <FeatureCard
              title="Seamless Loyalty Program Integration"
              description="Create a seamless experience with an integrated loyalty program across offline channels, allowing customers to earn and redeem rewards effortlessly."
              gradient="from-yellow-300 via-yellow-400 to-yellow-500"
            />
            <FeatureCard
              title="Say Goodbye to Boring Rewards!"
              description="Meet RkR Coins: The new-age digital currency that combines loyalty points, instant cashback, and special offers for every purchase you make at stores."
              gradient="from-yellow-400 via-yellow-500 to-yellow-600"
            />
            <FeatureCard
              title="Gamify Marketing and Advertising Strategies"
              description="Use the Reker World platform to drive your customers back for more. Engage them with exclusive AR experiences that keep them excited and loyal."
              gradient="from-yellow-500 via-yellow-600 to-yellow-700"
            />
          </div>

          {/* Auth Form */}
          <div className="relative group">
            <div className="absolute -inset-1 bg-gradient-to-r from-yellow-400 to-yellow-600 rounded-3xl blur-2xl opacity-20 group-hover:opacity-30 transition-opacity duration-500"></div>
            <div className="relative bg-gray-900/90 backdrop-blur-xl p-12 rounded-3xl border border-gray-800 shadow-2xl">
              <ToastContainer />
              <h2 className="text-4xl font-bold text-center mb-12">
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-yellow-600">
                  {isLogin ? "Welcome Back" : "Get Started"}
                </span>
              </h2>
              <form onSubmit={handleAuth} className="space-y-8">
                <InputField
                  icon={<FaEnvelope className="w-5 h-5" />}
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <InputField
                  icon={<FaLock className="w-5 h-5" />}
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  toggleVisibility={() => setShowPassword(!showPassword)}
                  showPassword={showPassword}
                />
                <button
                  type="submit"
                  disabled={loading}
                  className="relative w-full py-4 rounded-xl bg-gradient-to-r from-yellow-400 to-yellow-600 text-black font-semibold overflow-hidden group"
                >
                  <span className="relative z-10 inline-flex items-center justify-center w-full transition-transform duration-300 group-hover:scale-105">
                    {loading ? (
                      <>
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Processing...
                      </>
                    ) : (
                      isLogin ? "Log In" : "Sign Up"
                    )}
                  </span>
                  <div className="absolute inset-0 bg-gradient-to-r from-yellow-300 to-yellow-500 translate-y-full group-hover:translate-y-0 transition-transform duration-300"></div>
                </button>
                {isLogin && (
                  <p className="text-center text-gray-400">
                    Don't have an account? Contact us at{" "}
                    <a
                      href="mailto:brands@reker.world"
                      className="text-yellow-400 hover:text-yellow-300 transition-colors duration-300"
                    >
                      brands@reker.world
                    </a>
                  </p>
                )}
              </form>
            </div>
          </div>
        </div>
      </main>

      {/* Carousel Footer */}
      <footer className="relative bg-black/50 backdrop-blur-sm py-16">
        <div className="max-w-7xl mx-auto px-6">
          <LogoCarousel />
        </div>
      </footer>
    </div>
  );
};

const FeatureCard: React.FC<{
  title: string;
  description: string;
  gradient: string;
}> = ({ title, description, gradient }) => (
  <div className="group relative">
    <div className="absolute -inset-1 bg-gradient-to-r from-yellow-400 to-yellow-600 rounded-2xl blur opacity-20 group-hover:opacity-30 transition-opacity duration-500"></div>
    <div className="relative p-8 bg-gray-900/90 backdrop-blur-sm rounded-2xl border border-gray-800 transition-all duration-300 hover:transform hover:scale-[1.02]">
      <h3 className="text-2xl font-bold">
        <span className={`text-transparent bg-clip-text bg-gradient-to-r ${gradient}`}>
          {title}
        </span>
      </h3>
      <p className="mt-4 text-gray-300 leading-relaxed">{description}</p>
    </div>
  </div>
);

const InputField: React.FC<{
  icon: React.ReactNode;
  type: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  toggleVisibility?: () => void;
  showPassword?: boolean;
}> = ({
  icon,
  type,
  placeholder,
  value,
  onChange,
  toggleVisibility,
  showPassword,
}) => (
  <div className="relative group">
    <div className="absolute -inset-0.5 bg-gradient-to-r from-yellow-400 to-yellow-600 rounded-xl blur opacity-20 group-hover:opacity-30 transition-opacity duration-300"></div>
    <div className="relative flex items-center">
      <span className="absolute left-4 text-gray-400 group-hover:text-yellow-400 transition-colors duration-300">
        {icon}
      </span>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="w-full pl-12 pr-12 py-4 bg-gray-800/90 backdrop-blur-sm rounded-xl border border-gray-700 focus:border-yellow-500/50 focus:ring-2 focus:ring-yellow-500/20 text-gray-200 placeholder-gray-400 transition-all duration-300"
        required
      />
      {toggleVisibility && (
        <button
          type="button"
          onClick={toggleVisibility}
          className="absolute right-4 text-gray-400 hover:text-yellow-400 transition-colors duration-300 focus:outline-none"
        >
          {showPassword ? <FaEyeSlash className="w-5 h-5" /> : <FaEye className="w-5 h-5" />}
        </button>
      )}
    </div>
  </div>
);

export default LoginSignup;