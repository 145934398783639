import React, { useState } from "react";
import { db } from "../firebaseConfig";
import { collection, addDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaUser, FaEnvelope, FaEdit, FaComment } from "react-icons/fa";

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "contacts"), formData);
      toast.success("Message sent successfully!");
      setFormData({ name: "", email: "", subject: "", message: "" });
    } catch (error) {
      toast.error("Error submitting form.");
      console.error("Error submitting form: ", error);
    }
  };

  return (
    <div className="relative min-h-screen bg-black text-white overflow-hidden">
      {/* Animated Background Pattern */}
      <div className="fixed inset-0 opacity-20">
        <div className="absolute inset-0 bg-[linear-gradient(45deg,transparent_25%,rgba(68,68,68,.2)_50%,transparent_75%,transparent_100%)] bg-[length:20px_20px] animate-pattern"></div>
      </div>

      {/* Gradient Orbs */}
      <div className="fixed inset-0 overflow-hidden pointer-events-none">
        <div className="absolute top-0 -left-4 w-96 h-96 bg-yellow-500 rounded-full mix-blend-multiply filter blur-xl opacity-20 animate-blob"></div>
        <div className="absolute top-0 -right-4 w-96 h-96 bg-yellow-500 rounded-full mix-blend-multiply filter blur-xl opacity-20 animate-blob animation-delay-2000"></div>
        <div className="absolute -bottom-8 left-20 w-96 h-96 bg-yellow-500 rounded-full mix-blend-multiply filter blur-xl opacity-20 animate-blob animation-delay-4000"></div>
      </div>

      {/* Content Container */}
      <div className="relative max-w-7xl mx-auto px-6 py-24">
        <ToastContainer />
        
        {/* Header Section */}
        <div className="text-center mb-16 space-y-4">
          <h1 className="text-7xl font-black tracking-tight">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-300 to-yellow-600">
              Let's Get in Touch!
            </span>
          </h1>
          <p className="text-xl text-gray-300 max-w-2xl mx-auto">
            Whether you have a question, want to collaborate, or just want to
            say hi – we're here! Share your ideas, and let's work together
            towards a better, greener future.
          </p>
        </div>

        <div className="grid lg:grid-cols-2 gap-16 items-start">
          {/* Left Section - Contact Form */}
          <div className="relative group">
            <div className="absolute -inset-1 bg-gradient-to-r from-yellow-400 to-yellow-600 rounded-3xl blur-2xl opacity-20 group-hover:opacity-30 transition-opacity duration-500"></div>
            <div className="relative bg-black/80 backdrop-blur-xl p-8 lg:p-12 rounded-3xl border border-yellow-500/10">
              <h2 className="text-3xl font-bold mb-8 text-center">
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-yellow-600">
                  Drop Us a Message
                </span>
              </h2>
              
              <form onSubmit={handleSubmit} className="space-y-6">
                <InputField
                  icon={<FaUser />}
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                />
                <InputField
                  icon={<FaEnvelope />}
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <InputField
                  icon={<FaEdit />}
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  value={formData.subject}
                  onChange={handleChange}
                />
                <div className="relative group">
                  <div className="absolute inset-0 bg-gradient-to-r from-yellow-400/20 to-yellow-600/20 rounded-xl blur-sm"></div>
                  <div className="relative flex">
                    <span className="absolute left-4 top-4 text-yellow-500/50">
                      <FaComment />
                    </span>
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      placeholder="Your Message"
                      rows={4}
                      className="w-full pl-12 pr-4 py-4 bg-black/50 rounded-xl border border-yellow-500/10 focus:border-yellow-500/30 focus:ring-2 focus:ring-yellow-500/20 text-white placeholder-gray-400 resize-none"
                      required
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  className="relative w-full group"
                >
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-yellow-400 to-yellow-600 rounded-xl blur opacity-30 group-hover:opacity-100 transition duration-300"></div>
                  <div className="relative px-6 py-4 bg-black rounded-xl leading-none flex items-center justify-center">
                    <span className="text-yellow-500 group-hover:text-yellow-400 transition duration-300">
                      Send Message
                    </span>
                  </div>
                </button>
              </form>
            </div>
          </div>

          {/* Right Section - Map and Info */}
          <div className="space-y-8">
            {/* Map Section */}
            <div className="relative group">
              <div className="absolute -inset-1 bg-gradient-to-r from-yellow-400 to-yellow-600 rounded-3xl blur-2xl opacity-20 group-hover:opacity-30 transition-opacity duration-500"></div>
              <div className="relative bg-black/80 backdrop-blur-xl p-8 rounded-3xl border border-yellow-500/10">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890.5379110531184!2d77.6333333!3d12.9141417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae14100972bc39%3A0x1a24a5db7f69c6ed!2s1502%2C%2019th%20Main%20Rd%2C%201st%20Sector%2C%20HSR%20Layout%2C%20Bengaluru%2C%20Karnataka%20560102%2C%20India!5e0!3m2!1sen!2sus!4v1695115202567!5m2!1sen!2sus"
                  width="100%"
                  height="300"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen
                  aria-hidden={false}
                  tabIndex={0}
                  title="Google Map of HSR Layout, Bengaluru"
                  className="rounded-xl"
                />
              </div>
            </div>

            {/* Company Info */}
            <div className="relative group">
              <div className="absolute -inset-1 bg-gradient-to-r from-yellow-400 to-yellow-600 rounded-3xl blur-2xl opacity-20 group-hover:opacity-30 transition-opacity duration-500"></div>
              <div className="relative bg-black/80 backdrop-blur-xl p-8 rounded-3xl border border-yellow-500/10">
                <h3 className="text-2xl font-bold mb-6">
                  {/* <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-yellow-600">
                    Company Information
                  </span> */}
                </h3>
                <div className="space-y-4 text-gray-300">
                  <p className="text-lg">
                    {/* <strong className="text-yellow-400">Address:</strong><br /> */}
                    Evolvevol Tech Pvt. Ltd.<br />
                    1502, 19th Main Rd, 1st Sector,<br />
                    HSR Layout, Bengaluru, Karnataka 560102
                  </p>
                  <p className="text-lg">
                    <strong className="text-yellow-400">CIN:</strong><br />
                    U62020KA2024PTC194700
                  </p>
                  <p className="text-lg">
                    <strong className="text-yellow-400">GSTIN:</strong><br />
                    29AAICE4839Q1ZQ
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const InputField: React.FC<{
  icon: React.ReactNode;
  type: string;
  name: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ icon, type, name, placeholder, value, onChange }) => (
  <div className="relative group">
    <div className="absolute inset-0 bg-gradient-to-r from-yellow-400/20 to-yellow-600/20 rounded-xl blur-sm"></div>
    <div className="relative flex">
      <span className="absolute left-4 top-1/2 transform -translate-y-1/2 text-yellow-500/50">
        {icon}
      </span>
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="w-full pl-12 pr-4 py-4 bg-black/50 rounded-xl border border-yellow-500/10 focus:border-yellow-500/30 focus:ring-2 focus:ring-yellow-500/20 text-white placeholder-gray-400"
        required
      />
    </div>
  </div>
);

export default Contact;