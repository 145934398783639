import React, { useState } from "react";
import { CheckCircle, XCircle, ChevronDown, ChevronUp } from "lucide-react";
import { useNavigate } from "react-router-dom";

interface Plan {
  name: string;
  price: string;
  description: string;
  features: boolean[];
}

interface FAQ {
  question: string;
  answer: string[];
}

const DashboardFeaturesByPlan: React.FC = () => {
  const navigate = useNavigate();
  const [openFAQ, setOpenFAQ] = useState<number | null>(null);

  const toggleFAQ = (index: number): void => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  const features: string[] = [
    "Purchase history",
    "Total points earned/redeemed",
    "Advanced campaigns",
    "Store-specific AR performance",
    "Store walk-in insights",
    "Detailed rewards impact analysis",
    "Compare up to 5 stores",
    "Unlimited stores",
    "Custom branding",
    "Dedicated account manager",
  ];

  const plans: Plan[] = [
    {
      name: "Basic",
      price: "Free",
      description:
        "Perfect for single-store small businesses starting with AR marketing.",
      features: [
        true,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
    },
    {
      name: "Growth",
      price: "INR 4999/month",
      description: "Up to 2 stores, featuring AR marketing & branding.",
      features: [true, true, true, true, true, true, false, false, false, true],
    },
    {
      name: "Enterprise",
      price: "INR 9999/month",
      description:
        "Up to 5 store businesses in a city, with advanced AR marketing & analytics.",
      features: [true, true, true, true, true, true, true, false, true, true],
    },
    {
      name: "Custom Enterprise",
      price: "Tailored",
      description:
        "For large businesses with 5+ stores in multiple locations and Cities.",
      features: [true, true, true, true, true, true, true, true, true, true],
    },
  ];

  const faqs: FAQ[] = [
    {
      question: "What is Reker World Platform?",
      answer: [
        "First-of-its-kind only AR loyalty SaaS platform.",
        "Cloud-based platform for managing and enhancing customer loyalty programs.",
        "Helps businesses create, track, and optimize AR powered loyalty rewards.",
      ],
    },
    {
      question: "What does Reker World offer?",
      answer: [
        "Store-Based Rewards",
        "Tiered Loyalty Programs",
        "Gamification",
        "Referral Programs",
        "Omnichannel Integration",
        "Personalized Rewards",
        "User Analytics & Insights",
      ],
    },
    {
      question: "Why Reker World?",
      answer: [
        "Boost sales with Gamified Loyalty!",
        "Users Earn, play & redeem with Newest Climate Currency AR rewards & tier-based perks.",
        "More visits = More sales.",
      ],
    },
  ];

  return (
    <div className="min-h-screen bg-gray-900">
      {/* Hero Section with Animation */}
      <div className="relative overflow-hidden bg-gray-900 py-24">
        <div className="absolute inset-0 bg-gradient-to-r from-yellow-500/10 to-yellow-500/5" />
        <div className="container mx-auto px-4 relative">
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-6xl font-bold text-yellow-400 mb-8 animate-fade-in">
              Explore Gamified Loyalty Program
            </h1>
            <p className="text-xl text-gray-300 mb-12">
              Transform your business with AR-powered loyalty rewards and
              gamification
            </p>
          </div>
        </div>
      </div>

      {/* Pricing Section */}
      <div className="container mx-auto px-4 py-16">
        <div className="grid md:grid-cols-4 gap-8">
          {plans.map((plan, index) => (
            <div
              key={index}
              className={`relative rounded-2xl shadow-lg hover:shadow-yellow-500 p-8 ${
                index === 2
                  ? "bg-gradient-to-b from-yellow-500/20 to-gray-800 border-2 border-yellow-400"
                  : "bg-gray-800"
              } transform hover:scale-105 transition-all duration-300`}
            >
              {index === 2 && (
                <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-yellow-400 text-gray-900 px-4 py-1 rounded-full text-sm font-bold">
                  Most Popular
                </div>
              )}
              <div className="text-center mb-8">
                <h3 className="text-2xl font-bold text-yellow-400 mb-4">
                  {plan.name}
                </h3>
                <div className="text-4xl font-bold text-white mb-4">
                  {plan.price}
                </div>
                <p className="text-gray-400">{plan.description}</p>
              </div>
              <button
                onClick={() => navigate("/contact")}
                className={`fixed bottom-3 left-0 right-0 mx-auto w-fit py-3 px-6 rounded-xl font-semibold transition-colors duration-300 ${
                  index === 2
                    ? "bg-yellow-400 hover:bg-yellow-500 text-gray-900"
                    : "bg-gray-700 hover:bg-gray-600 text-white"
                }`}
              >
                Get Started
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Features Table with Modern Design */}
      <div className="container mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center text-yellow-400 mb-12">
          Compare Features
        </h2>
        <div className="bg-gray-800/50 rounded-2xl shadow-xl backdrop-blur-sm">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="px-8 py-6 text-left text-lg font-bold text-yellow-400 border-b border-gray-700">
                    Features
                  </th>
                  {plans.map((plan, index) => (
                    <th
                      key={index}
                      className="px-8 py-6 text-center text-lg font-bold text-yellow-400 border-b border-gray-700"
                    >
                      {plan.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {features.map((feature, featureIndex) => (
                  <tr key={featureIndex} className="group">
                    <td className="px-8 py-6 text-gray-300 font-medium border-b border-gray-700/50 group-hover:bg-gray-800/50">
                      {feature}
                    </td>
                    {plans.map((plan, planIndex) => (
                      <td
                        key={planIndex}
                        className="px-8 py-6 text-center border-b border-gray-700/50 group-hover:bg-gray-800/50"
                      >
                        {plan.features[featureIndex] ? (
                          <CheckCircle className="h-6 w-6 text-green-400 mx-auto" />
                        ) : (
                          <XCircle className="h-6 w-6 text-red-400 mx-auto" />
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* FAQ Section with Modern Accordion */}
      <div className="container mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center text-yellow-400 mb-12">
          Know Reker World
        </h2>
        <div className="max-w-3xl mx-auto space-y-6">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="bg-gray-800/50 rounded-xl backdrop-blur-sm overflow-hidden border border-gray-700/50"
            >
              <button
                className="w-full px-8 py-6 flex justify-between items-center hover:bg-gray-700/30 transition-colors duration-300"
                onClick={() => toggleFAQ(index)}
              >
                <h3 className="text-xl font-semibold text-yellow-400">
                  {faq.question}
                </h3>
                {openFAQ === index ? (
                  <ChevronUp className="text-yellow-400 w-6 h-6" />
                ) : (
                  <ChevronDown className="text-yellow-400 w-6 h-6" />
                )}
              </button>
              {openFAQ === index && (
                <div className="px-8 py-6 bg-gray-700/20">
                  <ul className="space-y-4">
                    {faq.answer.map((point, i) => (
                      <li key={i} className="flex items-start text-gray-300">
                        <span className="mr-3 text-yellow-400">•</span>
                        <span>{point}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DashboardFeaturesByPlan;
